import React from 'react';
import '../styles/HomeStyles.css';

function HomePage() {
  return (
    <div className="homeStyles">
      <h2>
        Welcome to our page!
      </h2>
      <p className="loremIpsum">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Odit aspernatur vero cumque laudantium unde quibusdam,
        ipsa et animi quidem, voluptatum temporibus accusamus
        dignissimos error! Esse ullam nostrum, unde sed ex nisi
        at omnis quasi dolore eos aliquid voluptates eum optio
        repudiandae voluptatum praesentium? Quas, tenetur ut. Ipsum quam rerum iure.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Odit aspernatur vero cumque laudantium unde quibusdam,
        ipsa et animi quidem, voluptatum temporibus accusamus
        dignissimos error! Esse ullam nostrum, unde sed ex nisi
        at omnis quasi dolore eos aliquid voluptates eum optio
        repudiandae voluptatum praesentium? Quas, tenetur ut. Ipsum quam rerum iure.
      </p>
    </div>
  );
}

export default HomePage;
